import { v4 as uuidv4 } from 'uuid';
import { Button, Card, Checkbox, Col, Input, Row, Typography } from "antd"
import { DeleteOutlined, EditOutlined, ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons'
import { CSSProperties, useCallback, useMemo, useState } from "react"
import { UseTaskListContext } from "../contexts/useTodoManager"
import { getTaskByUid } from "../utils"
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { TaskType } from '../types';

interface TaskProps {
    uid?: string
    isEditing?: boolean
}

const style: CSSProperties = {
    margin: "12px 0 12px 0",
}

const textStyle: CSSProperties = {
    textAlign: "start"
}

const hasUserEdit = (initialTask: TaskType, currentTask: Pick<TaskType, "title" | "description">)=>  initialTask.title !== currentTask.title || initialTask.description !== currentTask.description;

export const Task = ({uid, isEditing = false}: TaskProps)=> {
    const {action, taskList} = UseTaskListContext();
    const initialTask = useMemo(()=> getTaskByUid(taskList, uid ?? ""),[taskList, uid])
    const [title, setTitle] = useState(initialTask.title);
    const [description, setDescription] = useState(initialTask.description);
    const [completed, setCompleted] = useState(initialTask.complete);
    
    const isCreated = !!initialTask.uid;
    const [isEditable, setIsEditable] = useState(!isCreated || isEditing)
    
    const onSave = useCallback(()=> {
        const data = {
            uid: isCreated ? initialTask.uid : uuidv4() , 
            title,
            description,
            complete: completed
        }
        
        if(isCreated) {
            if(hasUserEdit(initialTask, {title, description})) {
                action.update(data);
            }
            setIsEditable(false);
        } else {
            action.add(data);
            setTitle("");
            setDescription("");
            setCompleted(false);
        }   
    },[isCreated, initialTask, title, description, completed, action]);

    const onRemove = useCallback(()=> {
        action.remove(initialTask.uid);
    },[action, initialTask.uid])

    const onEdit = ()=> setIsEditable(true);

    const handleOnCheckbox = useCallback((e: CheckboxChangeEvent)=> {
        action.update({
            uid: initialTask.uid, 
            title,
            description,
            complete: e.target.checked
        });
        setCompleted(e.target.checked);
    }, [action, description, initialTask.uid, title]);

    return (
        <Card size="small" style={style} >
            <Row>
                <Col span={24}>
                    <Row gutter={[16,16]} justify="start" align='middle'>
                        <Col span={2}>
                            {isCreated &&
                            <Col>
                                <Row justify="center" align="middle">
                                    <Col><Checkbox checked={completed} onChange={handleOnCheckbox} /></Col>
                                </Row>
                            </Col>   
                        }
                        </Col>
                        <Col span={20}>
                            <Col span={24} >
                                <Row>
                                    {isEditable ? 
                                    <Input bordered={false} style={textStyle} size='small' value={title} onChange={(e => setTitle(e.currentTarget.value))} placeholder="Title" title="Title" type="text" />:
                                    <Typography.Text style={textStyle} delete={completed}>{title}</Typography.Text>
                                    }
                                </Row>
                                
                            </Col>
                            <Col span={24}>
                                <Row>
                                    {isEditable ?
                                    <Input bordered={false} style={{...textStyle, color: "#A0A0A0"}} size='small' value={description} onChange={(e=> setDescription(e.currentTarget.value))} placeholder="Description" title="Description" type="text" />:
                                    <Typography.Text style={textStyle}  type="secondary" delete={completed}>{description}</Typography.Text>
                                    }
                                </Row>
                            </Col>
                        </Col>
                        <Col span={2}>
                            <Row justify='end'>
                                {!isEditable &&  <Button onClick={onEdit} type="text"><EditOutlined style={{color:'#457b9d'}} /></Button> }
                                {isEditable && isCreated &&  
                                    <Row justify='end'>
                                        <Col span={24}><Button onClick={onSave} type="text"><ArrowLeftOutlined style={{color:'#457b9d'}} /></Button></Col>
                                        <Col span={24}><Button onClick={onRemove} type="text"><DeleteOutlined style={{color:'#e63946'}} /></Button></Col>
                                    </Row>
                                }
                                {isEditable && !isCreated &&  
                                    <Row justify='end'>
                                        <Col span={24}><Button onClick={onSave} type="text"><SaveOutlined style={{color:'#457b9d'}} /></Button></Col>
                                    </Row>
                                }
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>        
    )
}