import { Col, Menu, Row, Typography } from "antd"
import { LogoutOutlined } from "@ant-design/icons"
import MenuItem from "antd/lib/menu/MenuItem"
import { UseAuthContext } from "../../contexts/useAuthContext"


export const HeaderMenu = ()=> {
    const { signOut } = UseAuthContext();

    return (
    <Menu>
        <MenuItem onClick={()=> signOut()}>
            <Row gutter={[16,16]}>
                <Col><LogoutOutlined /></Col>
                <Col><Typography.Text>Logout</Typography.Text></Col>                
            </Row>
        </MenuItem>
    </Menu>)

}