import { doc, collection, CollectionReference, deleteDoc, getFirestore, setDoc, getDocs, query } from "firebase/firestore";
import { TaskType } from "../types";
import { firebaseApp } from "./firebase";

const firestore = getFirestore(firebaseApp);
const taskListPath = process.env.REACT_APP_TASK_LIST_COLLECTION ?? "";

let taskListCollection: CollectionReference<TaskType> | null = null;

export const initializeCollections = (useId: string)=> {
    taskListCollection  = collection(firestore, `${useId}/user/${taskListPath}`) as CollectionReference<TaskType>;
}

export const FirestoreManager = ()=> {
    return {
        taskList:{
            fetch: async () => {
                if(taskListCollection) {
                    const queryResult = query(taskListCollection);
                    const querySnapshot = await getDocs(queryResult);
                    return querySnapshot.docs
                }
            },
            update: async (data: TaskType)=> {
                if(taskListCollection) {
                    const myDoc = doc(taskListCollection, data.uid);
                    await setDoc(myDoc, data);
                }
            },
            remove: async (uid: string)=> {
                if(taskListCollection) {
                    const myDoc = doc(taskListCollection, uid);
                    await deleteDoc(myDoc);
                }
            }
        }
    }
}



