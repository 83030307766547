import { TaskType, TaskList, TodoManagerActions } from "../types"

export const TodoManager = (taskList: TaskList, callback: (result: TaskList, task: TaskType,  method: keyof TodoManagerActions)=> void): TodoManagerActions => {
    return {
        add: (task: TaskType)=> callback({...taskList, [task.uid]:task}, task , "add"),
        update: (task: TaskType) => callback({...taskList, [task.uid]: task },task, "update"), 
        complete: (uid: string)=> {
            const result = {...taskList, [uid]: {...taskList[uid], complete: true}};
            callback(result, result[uid] , "complete")
        },
        uncomplete: (uid: string)=> {
            const result ={...taskList, [uid]: {...taskList[uid], complete: false}}; 
            callback(result, result[uid], "uncomplete");
        },
        remove: (uid: string)=> {
            const list = {...taskList };  delete list[uid]
            callback(list, taskList[uid], "remove");
        },
    }
}