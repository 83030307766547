import React, { useEffect, useState } from "react";
import { TaskList, TaskType, TodoListContextType, TodoManagerActions, } from "../types";
import { TodoManager } from "../todo-manager/todoManager";
import { FirestoreManager, initializeCollections } from "../firebase/firestore";
import { UseAuthContext } from "./useAuthContext";

export const defaultTodoList: TodoListContextType = {
        taskList: {},
        action: {
            add: ()=> null,
            remove: ()=> null,
            update: ()=> null,
            complete: ()=> null,
            uncomplete: ()=> null
        }
};

export const TaskListContext = React.createContext<TodoListContextType>(defaultTodoList);

export  const TaskListContextWrapper: React.FunctionComponent = ({children})=> {    
    const [taskList, setTaskList] = useState<TaskList>({});
    const {credential} = UseAuthContext();

    useEffect(()=> {
        if(credential) {
            initializeCollections(credential.uid)
        }
    }, [credential]);

    useEffect(()=> {
        const fetchData = async ()=> {
            const docs = await FirestoreManager().taskList.fetch()    
            if(docs) {
            const taskListResult = docs?.reduce((list, doc) => {
                const data = {
                    uid: doc.get("uid"),
                    title: doc.get("title"),
                    description: doc.get("description"),
                    complete: doc.get("complete")
                }                 
                return {...list, [data.uid]: data}
            }, {})
            setTaskList(taskListResult)
            }
        }
        fetchData();
    },[])


    const action = TodoManager(taskList, async (result: TaskList, task: TaskType, method: keyof TodoManagerActions)=> {
        switch(method) {
            case "add":
            case "update":
            case "complete":
            case "uncomplete":
               FirestoreManager().taskList.update(task);             
            break;
            case "remove":
                FirestoreManager().taskList.remove(task.uid)
        }   
        setTaskList(result);
    });
    

    return (
        <TaskListContext.Provider value={{
            taskList,
            action
        }}>
        {children}
    </TaskListContext.Provider>)
}