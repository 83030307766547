import { browserLocalPersistence, getAuth, GoogleAuthProvider, signInWithPopup, User, UserCredential, signOut as firebaseSignOut } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfig = {
    apiKey:process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId:process.env.REACT_APP_ID
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);

const googleProvider = new GoogleAuthProvider();
const auth = getAuth();
auth.setPersistence(browserLocalPersistence);


const signIn = async (callback: (credential: UserCredential)=> void)=> {
    try{
        const credential = await signInWithPopup(auth, googleProvider);
        callback(credential);
    } catch(e) {
        new Error(`${e}`)
    }
}

const signOut = async (callback: ()=> void)=> {
    try{
        await firebaseSignOut(auth);
        window.localStorage.removeItem(`firebase:authUser:${firebaseConfig.apiKey}:[DEFAULT]`); 
        callback();
    } catch(e) {
        new Error(`${e}`)
    }
} 

const isSigned = (callback: (user: User) => void)=> {
    try {
        const user: User = JSON.parse(window.localStorage.getItem(`firebase:authUser:${firebaseConfig.apiKey}:[DEFAULT]`) ?? "");
        callback(user)
    } catch {
        return null;
    }
}

export { signIn, isSigned, signOut }