import { Button, Col, Image, Row, Typography } from "antd"
import { GoogleOutlined } from "@ant-design/icons"
import { UseAuthContext } from "../contexts/useAuthContext";
import { CSSProperties } from "react";

const style: CSSProperties = {
    height: "100%"
}

export const  Login = ()=> {
    const {auth} = UseAuthContext()

    return (
        <Row style={style} align="middle">
            <Col span={24}>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Image preview={false} width={62} height={62} src="./logo192.png"/>
                    </Col>
                    <Col span={24}>
                        <Button onClick={()=> auth()}>
                            <GoogleOutlined />
                            <Typography.Text>Google sign-in</Typography.Text>
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row> 
    );
}