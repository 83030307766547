import { Avatar, Typography } from "antd";
import { UseAuthContext } from "../contexts/useAuthContext"


export const UserAvatar = ()=> {
        const { credential } = UseAuthContext();

        return <>
            <Avatar src={credential?.photoURL} />
            <Typography.Text>{credential?.displayName}</Typography.Text>
        </>
}