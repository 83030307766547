import { Dropdown, PageHeader, Image, Typography, Affix } from "antd";
import { CSSProperties } from "react";
import { UserAvatar } from "../userAvatar";
import { HeaderMenu } from "./headerMenu";

const styleHeader: CSSProperties = {
    background: "white"
}

const styleTitle: CSSProperties = {
    color: "#73a5b3",
    margin: 0,
    textAlign: "center"
}

export const Header = ()=> {
    return (
        <Affix >
            <PageHeader style={styleHeader} title={<Image width={32} src="./favicon.ico"/>}
            subTitle={<Typography.Title style={styleTitle} level={4}>Todo</Typography.Title>}
            extra={[
                <Dropdown key="Menu" trigger={['click']} placement="bottom" overlay={<HeaderMenu/>}>
                    <div onClick={e => e.preventDefault()}>
                        <UserAvatar />
                    </div>
                </Dropdown>  
            ]}
            ></PageHeader>
        </Affix>
    )
}