import { User } from "firebase/auth";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Login } from "../components/login";
import { isSigned, signIn, signOut as firebaseSignOut } from "../firebase/firebase";
import { AuthContextType } from "../types";


const initialAuthContext: AuthContextType = {
    credential: null,
    auth: async()=> {},
    signOut: async()=> {}
}

export const AuthContext = React.createContext<AuthContextType>(initialAuthContext);

export const AuthContextWrapper: FunctionComponent = ({children})=> {

    const [credential, setCredential] = useState<User | null>(null);
    const auth = async ()=> await signIn((credential)=> {
        setCredential(credential.user)
    });
    
    const signOut = async ()=> await firebaseSignOut(()=> {
        setCredential(null)
    });  

    useEffect(()=> {
        isSigned((user: User)=> {
            setCredential(user)
        })
    },[]) 
    
    return (
    <AuthContext.Provider value={{
        credential,
        auth,
        signOut
    }}>
        {!credential && <Login/ >} 
        {credential && children} 
    </AuthContext.Provider> 
    );

} 