import { Affix, Col, Divider, Row } from "antd"
import { CSSProperties } from "react"
import { UseTaskListContext } from "../contexts/useTodoManager"
import { TaskType } from "../types"
import { ObjectToArray } from "../utils"
import { Task } from "./task"

const createTaskStyleSection: CSSProperties = {
    backgroundColor: "white"
}

export const TaskList = ()=> {
    const { taskList } = UseTaskListContext()
    return (
    <Row justify="center">
        <Col xxl={14} xl={16} lg={18} md={20} sm={24} span={24} >
            <Affix offsetTop={60}>
                <div style={createTaskStyleSection}>
                    <Col span={24}><Task/></Col>
                    <Divider>Tasks</Divider>
                </div>
            </Affix>
        </Col>
        <Col xxl={14} xl={16} lg={18} md={20} sm={24} span={24}>
            { ObjectToArray<TaskType>(taskList).map(task=> <div key={task.uid}><Task uid={task.uid} /></div>)}
        </Col>
    </Row>
    )
}