import './App.css';
import { Header } from './components/header';
import { TaskList } from './components/taskList';
import { AuthContextWrapper } from './contexts/authContext';
import { TaskListContextWrapper } from './contexts/taskListContext';

function App() {
   return (
    <div className="App">
      <AuthContextWrapper>
        <TaskListContextWrapper>
          <Header />
          <TaskList />    
        </TaskListContextWrapper>
      </AuthContextWrapper>      
    </div>
  );
}

export default App;
